
.features {

	hr {
		width: 50px;
		margin-bottom: 90px;
	}
}

.feature-list-title {
	margin-bottom: 1.5em;
}

@mixin feature-list($thumb-size, $margin-bottom, $align: "*") {

	@extend .list-unstyled;

	.feature-list-item {
		@extend .media;
		margin-bottom: $margin-bottom;

		p {
			margin-bottom: 0;
		}
	}

	img {

		@extend .d-flex;

		width: $thumb-size;
		height: $thumb-size;

		margin-right: 3rem;
	}

	.feature-item-body {
		@extend .media-body;
		@extend #{$align};

	}
} 

.feature-list {

	@include feature-list(100px, 2rem);
}

.feature-list-small {

	@include feature-list(1.5rem, 1rem);

	h4 {
		font-size: 1.3rem;
	}
	

}

.feature-header {

	width: 100%;
	height: 100%;
	position: relative;

	margin-top: $standard-content-top-margin;

	text-align: center;

}

.feature-title {

	> h1 {
		font-size: 48px;
		font-weight: 400;
		margin-bottom: 20px;
	}

	> h2 {
		font-size: 32px;
		font-weight: 300;
		margin-top: 0;
	}

	.invno:not(:last-child):after {
		content: ", "
	}

	margin-bottom: 2rem;
}


.feature-teaser {
	@include make-row();

	font-size: 18px;
	font-weight: 300;

	margin-bottom: 40px;

	div {
		@include make-col-ready();
		@include make-col(12);
	}

}

.feature-body {
	@include make-row();

	.feature-body-text {
		margin-bottom: 4em;
	}
}

.feature-content {
	@include make-col-ready();
	@include make-col(12);
}

.feature-content {
	padding-right: 15px;
}

.feature-image {

	float: right;

	padding-left: 15px;
	text-align: center;
	width: 50%;
	padding-bottom: 3em;

	img {
		@include img-fluid;

		max-height: 850px;
	}
}

.feature-dating {
	font-size: 1rem;
	font-style: italic;
	margin-top: 4px;
}

.feature-extras-mani {
	clear: both;

	.layers {
		display: flex;
		.layer-desc {
			text-align: center;
		}
	}

	.fragment-thumbs {
		display: flex;

		.fragment-thumb {
			text-align: center;
			&:not(:last-of-type) {
				margin-right: 16px;
			}
		}
	}

	.page-thumb {
		height: 70px;
		width: 120px;
		object-fit: cover;
		margin: 0 8px 0 0;

		&.not-available {
			font-family: $papyri-font-light;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.layer {
		margin-right: 8px;
	}

	.layer:not(:last-of-type) .layer-wrapper {
		border-right: 1px dotted scale-color($card-border-color, $lightness: 1%);
	}

	.layer-desc {
		margin-bottom: 16px;
		font-weight: bold;
	}

	.desc {
		margin: 2px 8px 16px 2px;
		font-size: 12px;
		border-bottom: 1px dotted scale-color($card-border-color, $lightness: 1%);

		&:before {
			@extend .octicon;

			margin-left: 2px;
			font-size: 12px;

			content: '\f02e';
			color: grey;

			padding: 4px 0 4px 0;
		}
	}
}

.feature-body {
	.bibliography {
		//font-size: 14px;
		.bibl-list {
			margin-bottom: 2rem;
		}

		ul {
			padding: 0;
		}

		li {
			list-style: none;
			margin-bottom: 0.5rem;
		}
		.note {
			font-family: $papyri-font-light;
		}
		.scope:not(:empty) {
			&:before {
				content: "(";
			}
			&:after {
				content: ")";
			}
		}
	}
}

.feature-extras-rahlfs, .feature-extras-tura {

	$thumb-size: 45px;

	.table, &.table {
		margin-bottom: 2em;
	}

	.table-row {
		display: grid;
		grid-template-columns: $thumb-size 1fr 1fr 1fr 1fr 1fr;
		grid-gap: 16px;
		align-items: center;

		&:not(:last-of-type) {
			margin-bottom: 12px;
		}
	}

	.header {
		justify-content: center;
		font-weight: bold;
	}

	.thumb {
		height: $thumb-size;
		width: $thumb-size;
		object-fit: cover;
		margin: 0 8px 0 0;
	}
}

.feature-extras-tura {

	.toc {
		margin-top: 2rem;
		padding-right: 2rem;

		ul  {

			list-style: none;
			padding-left: 0;

			li {
				margin-bottom: 1.5rem;
			}
		}

		.invno {
			font-weight: bold;
		}
	}

	.table {
		margin: 2rem 0 3rem 0;
	}
}

.feature-extras-bubastos {
	.pub-row {
		background-color: #9ABFD1;
		color: #f5fcff;
	}

	#p-bub-3-6 {
		ul {
			list-style: none;
			padding-left: 0;
		}

		li {
			margin-bottom: 1.5rem;
		}

		.invno {
			font-weight: bold;
		}
	}

	.falttafeln {
		.falttafel {
			img {
				height: 120px;
				width: 180px;
				object-fit: contain;
			}

			margin-right: 1rem;
		}
	}

	.thumbs {
		display: flex;
		flex-wrap: wrap;
		margin-top: 1rem;

		.thumb {
			text-align: center;
			img {
				height: 60px;
				width: 70px;
				object-fit: contain;
			}
		}

		.desc {
			margin: 8px 8px 16px 2px;
			padding-top: 2px;
			font-size: 12px;
			border-top: 1px dotted rgba(3, 3, 3, 0.125);

			text-align: start;

			white-space: nowrap;

		}
	}
}


.collapse-btn {
	.collapse-label:after {
		content: "einklappen"
	}

	&.collapsed .collapse-label:after {
		content: "ausklappen"
	}
}

.feature-extras-bank {

	.texts {
		clear: both;
	}

	.bank-item {

	}

	.thumb {
		@extend .img-thumbnail;

		max-height: 180px;
		width: 100%;
		object-fit: contain;
	}
}

.citation {
	text-indent: -1em;
	margin-left: 1em;
}
