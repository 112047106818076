  
// $navbar-default-toggle-icon-bar-bg:   #ffffff;
// $navbar-default-link-color:           #ffffff;
// $navbar-default-link-hover-color:     #ffffff;
// $navbar-default-link-hover-bg:        #9abfd1;  


// Papyri specific
$papyri-primary:                   #9f9788;
$papyri-primary-bright:            #F6F1E9;
// $papyri-brand-secondary:                 #7E95AA;
$papyri-brand-secondary:                 #2381AE;
$papyri-brand-secondary-bright:          #9ABFD1;

$papyri-font-blockquote:  "LatoLatinWebLight", Helvetica, Arial, sans-serif;
$papyri-font-thin:        "LatoLatinWebThin", Helvetica, Arial, sans-serif;
$papyri-font-light:       "LatoLatinWebLight", Helvetica, Arial, sans-serif;
$papyri-font-bold:        "LatoLatinWebBold", Helvetica, Arial, sans-serif;

$standard-content-top-margin: 80px;
$papyri-background: white;



// Bootstrap overrides

$footer-bg:                              #f5f5f5;

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1280px
) !default;


$primary:                          $papyri-primary;
$secondary:                         $papyri-brand-secondary;
$navbar-inverse-color:                   white;

$link-color:                             #7E95AA;
$link-hover-decoration:                  none;  

$btn-primary-bg:                        $papyri-brand-secondary;

$pagination-active-bg:                  $papyri-brand-secondary;
$pagination-active-border:              darken($papyri-brand-secondary, 5%);
  
$breadcrumb-bg:                         transparent;
$breadcrumb-padding-x:                  0;
$breadcrumb-padding-y:                  0;

$media-alignment-padding-x:             25px;

$card-spacer-x: 20px;

$jumbotron-bg:                          $papyri-primary-bright;

$font-family-sans-serif:  "LatoLatinWeb", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif-light:  "LatoLatinWebLight", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family:    "LatoLatinWebLight", Helvetica, Arial, sans-serif;
$headings-font-weight:    100;

$page-header-top-margin: 3em;

$footer-height: 100px;
$footer-margin: 25px;

$navbar-default-bg: #9f9788;

$primary-heading-margin-bottom: 40px;

// import bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// import fonts
$octicons-font-path: '../fonts/';
@import '_papyri-fonts.scss';
@import 'octicons.scss';

@import 'mixins';

// import papyri app components
@import 'papyri-about';
@import 'papyri-complex-search';
@import 'papyri-facets';
@import 'papyri-features';
@import 'papyri-homerica';
@import 'papyri-landing-page';
@import 'papyri-navbar';
@import 'papyri-publications';
@import 'papyri-results';
@import 'papyri-stats';
@import 'papyri-stueck';

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
  padding-bottom: 3rem;
  background-color: $papyri-background;
}


#tooltip {
    position: absolute;
    width: 200px;
    height: auto;
    padding: 10px;
    background-color: white;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
    pointer-events: none;
}

#tooltip.tooltip-hidden {
    opacity: 0;
}
#tooltip p {
    margin: 0;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 20px;
}


@include media-breakpoint-down(xs) {

  .navbar {
    margin-bottom: 0;
  }

}

@include media-breakpoint-up(lg) {
  .container-fluid {
    padding: 0;
  }
}

.full-width {
  padding-left: -15px;
  padding-right: -15px;
}


#content {
  margin-bottom: 100px;
}

.content {
  @include make-col-ready();


  @include make-col(10);
  @include make-col-offset(1);

  @include media-breakpoint-up(xl) {
    
    @include make-col(8);
    @include make-col-offset(2);
  }

  @include media-breakpoint-down(sm) {
    
    @include make-col(12);
    @include make-col-offset(0);
  }
}

.content-full-width {
  @include make-col(10);
  @include make-col-offset(1);
}

.content, .content-full-width {
  margin-top: $standard-content-top-margin;
}

#wrapper-main {
  float: none;
  margin: 0 auto;
}


.page-header {
  border-bottom: none;
  margin: $page-header-top-margin 0 2.5em 0;
  padding: 0;
}


// login dialog
#loginModal, #logedModal {

  text-align: center;

  .title,
  .inputgroup {
    /*padding: 15px 0;*/
  }

  .btn {
    padding: 10px 40px;
    margin-bottom: 30px;
  }

  .vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
    pointer-events:none; /* This makes sure that we can still click outside of the modal to close it */
  }
  .vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events:none;
  }
  .modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    height:inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events: all;
  }
}


/* Application styles could go here */


h1 {
  font-size: 30px;

  @include media-breakpoint-up(md) {
    font-size: 48px;
  }

  font-family: "LatoLatinWebThin", Helvetica, Arial, sans-serif;
  margin-bottom: $primary-heading-margin-bottom;
}
h2 {
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 0px;
  }
}
h3 {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 0px;
  }
}


label {
  font-weight: normal;
}

blockquote {
  font-family: $papyri-font-blockquote;
  font-style: italic;
}

p.error {
  color: $danger;
}

p.success {
  color: $success;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: inherit;
}


blockquote {
  padding-left: 4rem;
  padding-right: 1rem;
}

section {
  margin-bottom: 2rem;

  h2 {
    @include section-title;
  }

  h3 {
    margin-top: 2rem;
  }
}

.toc {

  h2 {
    border-bottom: none;
  }

  ol {
    list-style-position: inside;
    padding-left: 1.5em;
    &:first-child {
      padding-left: 0;
    }

    li:not(:last-of-type) {
      margin-bottom: 0.2em;
    }
  }
}


/* FOOTER ******************************/

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  // height: $footer-height;
  // line-height: $footer-height;

  padding: 2rem 1rem;

  background-color: $footer-bg;

  font-family: $font-family-sans-serif-light;

  a {
    color: #2381ae;
  }

  .footer-content {
    @extend .mx-auto;

    max-width: map-get($grid-breakpoints, xl) + 200;
  }

  .logos-left {
    display: inline-block;
    float: left;
    @extend .d-flex;
    @extend .align-items-center;
  }
  .logo-park {
    display: block;
    text-align: right;
  }

  .logos {
    
  }

  .copyright {
    display: inline-block;
    padding-right: 1rem;
  }
}

.input-group-append button {
  border-color: $input-border-color;
}

.image-viewer .modal-dialog {
  height: 90vh;
  .modal-content {
    height: 100%;
  }
}
