/* Komplexe Suche: Suchformular; */

.papyri-complex-search {
  padding-bottom: 1em;
}

.papyri-complex-search fieldset span.or {
  text-align: right;
}

.papyri-complex-search {
  
  h3 {
  	padding-bottom: 1em;
  }

  input[type="text"], select {
  	width: 100%;
  }

  #von, #bis {
		width: 80px;
		display: inline-block;
		margin-left: 1em;
  }

  input[type="checkbox"] {
  	margin-right: 0.5em;
  }

  .search-options {
  	padding-bottom: 1.5em;
  }

  .form-group {
    margin-bottom: 0.6rem;
  }
}

.papyri-complex-search .post {
  margin-right: 1em;
}

.papyri-complex-search .post, .papyri-complex-search .pre {
  width: 61px;
} 

.papyri-complex-search label {
  display: inline-block;
  padding-right: 1em;
}

#papyri-results {
	height: 100%;
}

/* Typeahead.js */

.twitter-typeahead {
	width: 100%;
}

.typeahead,
.tt-query,
.tt-hint {
  padding: 8px 12px;
  line-height: 30px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 190px;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 14px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;

}

.tt-suggestion p {
  margin: 0;
}
