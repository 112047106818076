#tooltip {
	#label {
		font-weight: 500;
	}
}

.statistik-header {
	padding-top: 2em;
	padding-bottom: 5em;
}

.statistik-header-title {
	font-size: 36px;
	text-align: center;
}

.statistik-intro {
	font-size: 18px;
	font-weight: 300;
	text-align: center;
}

.statistik-nav {
	padding-bottom: 2em;
	font-size: smaller;
}

.stat-section-title {
	font-size: 32px;
	font-weight: 400;
}

.stat-info {
	height: 4em;
	font-size: 18px;
	font-weight: 300;
}

.statistik-nav {
	span::after {
		content: ':';
	}

	ul {
		display: inline;
		padding-left: 0;
	}

	li {
		display: inline;

		&:not(:first-child) {
			&::before {
				content: ' – ';
			}
		}
	}
}

.percent {
	font-weight: normal;
	font-size: 22px;
	font-style: normal;;

	& > span::after {
		content: "%";
	}
}



// SVG

text.count {
	fill: white;
	font-size: 12px;
}