@import '../../node_modules/bootstrap/scss/bootstrap';

#homerica-content {

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    aside {
      position: sticky;
      top: 0.5rem;
      max-width: 15rem;
      align-self: flex-start;
      margin: $page-header-top-margin 3rem 0 0;
    }

    article {
      flex: 1;
    }
  }


  section {
    margin-bottom: 1rem;

    h2 {
      @include section-title;
    }
  }

  table {
    @extend .table;
    @extend .table-striped;
    border-collapse: collapse;
  }

  th, summary {
    position: sticky;
    background-color: $papyri-background;
  }

  summary {
    position: sticky;
    top: -1px;
    background-color: $papyri-background;
    list-style: none;
    font-size: 18px;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;

    &.heading-2 {
      @include section-title;

      &::before {
        font-size: $section-title-font-size;
        line-height: $section-title-line-height;
      }

      & ~ table th {
        top: $section-title-height;
      }
    }

    &.heading-3 {
      font-size: 18px;
      line-height: 1.5;
      padding: 5px 0;

      & ~ table th {
        top: 8px + 1.5 * 18px;
      }
    }

    &::before {
      @extend .octicon;
      display: inline-block;
      content: '\f078';
      margin-right: 0.5rem;

      transition: transform 250ms ease-out;
    }
  }

  details[open] summary {
    &::before {
      transform: rotate(90deg);
    }
  }

  .reflist {
    margin-top: 0.5rem;
    font-weight: bold;
  }
}