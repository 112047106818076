/* ABOUT / TEAM *************************/

.foerderung {
  .logos img {
    width: 250px;
    height: auto;

    margin-bottom: 4rem;
  }
}

.team {

  margin-top: 2em;
  margin-bottom: 1em;

  p {
    margin-bottom: 0;
  }

  div {
    margin-bottom: 1em;
  }

  img {
    width: 120px;
    height: auto;
  }

  .name {
    font-weight: bold;
  }

  .mail, .tel {
    font-size: smaller;
  }
}