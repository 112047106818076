@use "sass:math";

.stueck {
	
	.object-header {
		margin-bottom: 80px;
	}

	h1 {
		margin-bottom: 1rem;
	}

	h2 {
		padding-top: 1em;
		font-size: 36px;
	}

	h3 {
		font-size: 13px;
		margin-bottom: 0;
		font-weight: bold;

	}

	ul {
		list-style: none;
		padding-left: 0;
	}

	.feature {
		margin-bottom: -20px;
	}

	.feature-link-text {
		font-family: $papyri-font-light;
	}

	.feature-link {
		font-family: $papyri-font-light;
		font-size: 24px;
	}


	/* two-column layout */
	.stueck-content {
		@include make-row();
	}

	.img-column, .content-column {
		@include make-col-ready();
	}

 	.img-column {
		@include make-col(4);

		text-align: center;
	}
	.content-column {
		@include make-col(8);
	}	

	@include media-breakpoint-down(md) {
	 	
	 	.img-column {
			@include make-col(5);
		}
		.content-column {
			@include make-col(7);
		}
	}

	@include media-breakpoint-down(xs) {
	 	
	 	.img-column {
			@include make-col(12);

		}
		.content-column {
			@include make-col(12);
		}
	}

	/* all thumbnails */ 

	.thumbnail {
		display: table-cell;
		vertical-align: middle;
	}

	.desc {
		text-align: left;
		font-weight: bold;
		margin-top: 5px;
		margin-left: 2px;

		border-bottom: 1px dotted scale-color($card-border-color, $lightness: 1%);
	}
	.resolutions {
		margin-top: 5px;
		margin-bottom: 30px;
		margin-left: 2px;
		text-align: left;

		list-style: none;
		padding-left: 0;

		font-size: 9pt;

		li:before {
			@extend .octicon;

			// lock icon, see octicons.scss
			content: '\f02e';
			font-size: 0.8rem;
			padding-right: 0.25rem;
			color: gray;
		}

		li.no-access:before {
			content: '\f06a';
		}

	}


	

	/* larger main thumbnail */
	
	$thumb-margin: 10px;
	$thumb-spacing: 10px;
	
	@mixin image-layout($preview-thumb-width, $preview-thumb-margin) {

		$preview-thumb-frame-width: $preview-thumb-width + 2 * $preview-thumb-margin;
		$thumb-frame-size: math.div($preview-thumb-frame-width, 2) - $thumb-spacing;
		$thumb-size: $thumb-frame-size - 2*$thumb-margin;

		width: $preview-thumb-frame-width;

		.preview {
			.preview-img {
				width: $preview-thumb-frame-width;
				height: $preview-thumb-frame-width;
				padding: $preview-thumb-margin;
			
				img {
					max-width: $preview-thumb-width;
					max-height: $preview-thumb-width;
				}
			}

			.desc {
				width: $preview-thumb-frame-width;
			}
		}

		.thumb-img {
			
			height: $thumb-frame-size;
			width: $thumb-frame-size;

			img {
				max-width: $thumb-size;
				max-height: $thumb-size;
			}
		}
	}

	// vertically center images in container
	.preview-img, .thumb-img {
		@extend .d-flex;
		@extend .align-items-center;
		@extend .justify-content-center;
	}

	.img-alignment-wrapper {

		@include image-layout(220px, 20px);

		@include media-breakpoint-down(md) {
			@include image-layout(160px, 20px);
		}
		
		display: inline-block;

		.preview-wrapper {
			@include make-row();
		}

		.preview {
			@include make-col-ready();
			@include make-col(12);
		}

		.preview {
		
			.preview-img {
				text-align: center;
			}


		}

		.thumbs-wrapper {
			@include make-row($gutter: $thumb-spacing);
		}

		.thumb-frame {
			@include make-col-ready($gutter: $thumb-spacing);
			@include make-col(6);
		}

		.thumb-img {
			padding: $thumb-margin;
			text-align: center;
		}


		.info-wrapper {
			text-align: left;
			font-size: 13px;
		}
	}


	/* rows, columns */

	.data-block {
		@include make-row();
	}

	@mixin data-field($breakpoint, $font-size) {
		.data-field{

			margin-bottom: 10px;
			font-size: $font-size;

			.part-info {
				margin-bottom: 0;
			}

		}

		.field-label {
			@include make-col-ready();
			@include make-col(3);

			@include media-breakpoint-down($breakpoint) {
				@include make-col(12);
			}
			font-family: $papyri-font-light;
			word-wrap: normal;
		}

		.field-value {
			@include make-col-ready();
			@include make-col(9);

			@include media-breakpoint-down($breakpoint) {
				@include make-col(12);
			}

			//font-weight: bold;
		}

		.field-edition {
			@include make-col-ready();
			@include make-col(12);
			@include media-breakpoint-down($breakpoint) {
				@include make-col(12);
			}
		}

		.field-comment {
			@include make-col-ready();
			@include make-col(12);
		}
	}

	.object-info { @include data-field($breakpoint: md, $font-size: 1.1rem); }
	.layouts { @include data-field($breakpoint: md, $font-size: 0.9rem); }

	.object-info, .layouts {

		.data-field {
			@include make-row();

			&:not(:last-child) {
				border-bottom: 1px dotted $card-border-color;
			}

			padding-bottom: 10px;
		}

		.field-comment {
			padding: 0;
		}
	}

	.object-texts, .object-additional {
		@include data-field($breakpoint: lg, $font-size: 0.9rem);

		.data-block:not(:last-child) {
			border-bottom: 1px dotted $card-border-color;
			margin-bottom: 20px;
		}

		.data-field {
			@include make-col-ready();
			@include make-col(6);

			@include media-breakpoint-down(md) {
				@include make-col(12);
			}

			display: flex;
			flex-wrap: wrap;

			padding-left: 5px !important;
			padding-right: 5px !important;
		}

		.data-field.edition {
			@include make-col(12);
		}
	}

	.object-additional {
		@include make-row();

		.data-field {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}


	
	


	/* texts layout */

	.text {
		@extend .card;
	
		margin-bottom: 2rem;

		header {
			@extend .card-header;

			padding: 0.4rem $card-spacer-x;
		}
	}

	.text-inner {
		@extend .card-block;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.text-body {
		@include make-row();
	}

	/* manuscript layouts layout */

	//.layouts-wrapper { @extend .card-deck-wrapper;}
	.layouts { @extend .card-deck; }
	.layout {
		@extend .card;
		min-width: 300px;
		margin-bottom: 15px !important;

		header {
			@extend .card-header;
		}
		.layout-inner {
			@extend .card-block;
			margin: 15px 15px 0 15px;
		}
	}

	.edition {
		h2 {
			font-family: $papyri-font-light;
			font-size: 0.9rem;
			margin: 0 0 0.5rem 0;
		}

		.source-img {
			height: 1.1rem;
			width: auto;
			margin-left: 0.2rem;
			vertical-align: top;
		}

		h2:first-child {
			padding-top: 0;
		}

		#apparatus {

			@media (min-height: 150px) {
				-webkit-column-count: 3;
				   -moz-column-count: 3;
				        column-count: 3;
				-webkit-column-width: 15rem;
				   -moz-column-width: 15rem;
				        column-width: 15rem;         
			}

			br:first-child {
				display: none;
			}

			font-size: 13px;
		}

		.linenumber {
			margin-left: -1rem;
			float: left;
			font-size: 70%;
		}

		.textpart {
			margin-left: 1em;
		}

	}

	/* internal fields */

	@mixin lock-icon($font-size, $spacing) {
		&:after {
			@extend .octicon;

			// lock icon, see octicons.scss
			font-size: $font-size;
			vertical-align: super;
			content: '\f06a';
			margin-left: $spacing;
		}
	}

	.data-field.internal .field-label {
		@include lock-icon($font-size: 0.5rem, $spacing: 0.3rem);
	}

	h2.internal {
		@include lock-icon($font-size: 1rem, $spacing: 0.5rem);
	}

	
	/* additional notes / comments for single fields */
	.field-comment {
		font-style: italic;
	}


	/* fields that combine information from multiple manuscript parts */
	.part-info {

		list-style: none;
		padding-left: 0;

		.part-id + .part-value::before {
			content: ': ';
			margin-right: 0.25rem;
		}
		.part-id:empty + .part-value::before {
			content: "";
			margin-right: 0;
		}

		.part-id {
			font-variant: small-caps;
		}
	}


	/* specific fields */

	.locus:not(:empty)+.title:not(:empty):before {
			content: ": ";
		}


	/* bibliographic entries */
	.data-field.bibl {
		@include make-col(12);
		.field-label {
			margin-bottom: 1em;
		}

		.field-label, .field-value {
			@include make-col(12);
		}
	}

	ul.bibl {
		list-style: none;
		padding-left: 0;

		.entry-group {
			display: flex;
			li {
				margin-bottom: 0.5rem;
			}
		}

		> ul {
			display: flex;

		}
	}

	.bibl {
		.bibl-type {
			font-variant: small-caps;
			margin-right: 1rem;

			width: 7rem;
			min-width: 7rem;
		}
		.bibl-scope {
			font-style: italic;
			margin-left: 0.25rem;
		}
		.bibl-note {
			font-family: $papyri-font-light;
		}
	}

	
}

