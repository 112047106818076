/* Facettiertes Browsing */

$facet-bar-width: 220px;
$facet-bar-xs-width: 50px;

#papyri-facets {

  .offcanvas {
     @extend .flex-nowrap;
  }

  @include media-breakpoint-down(sm) {

    .offcanvas {
      position: relative;
      -webkit-transition: all 0.25s ease-out;
      -moz-transition: all 0.25s ease-out;
      transition: all 0.25s ease-out;
	    width: -moz-calc(100% + #{$facet-bar-width});
	    width: -webkit-calc(100% + #{$facet-bar-width});
	    width: -o-calc(100% + #{$facet-bar-width});
	    width: calc(100% + #{$facet-bar-width});
    }

    .offcanvas-left {
    	left: ($facet-bar-width * -1);
    }

    .offcanvas-left.active {
    	left: 0;
    }

  .toggle-filter-btn {
    display: initial;
  }

  }
}

.toggle-filter-btn {
  float: right;
  display: none;
}

.facet-sidebar {
   width: inherit;
  min-width: $facet-bar-width;
  max-width: $facet-bar-width;
  float: left;
  height:100%;
  position:relative;
  overflow-y:auto;
  overflow-x:hidden;
  margin-right: 30px;

  @include media-breakpoint-down(sm) {
  	border-right: 1px solid gray;
    padding-right: 8px;
  }

  > h2 {
  	margin-top: 0;
  	margin-bottom: 25px;
  }

  .facet-heading {
    padding: 0.6rem 10px;
  }

  .facet-heading > h5{
  	margin-top: 0;
  	margin-bottom: 0;

    font-size: 16px;
  }

  i {
    cursor: pointer;
  }

  ul.facet-list {
  	padding-left: 0;
  	list-style: none;

  } 

  li.facet-value {
  	overflow: hidden;
  	margin: 3px 0;
  	padding: 3px 6px;

  	&:hover {
  		//background-color: $well-bg;
  	}

  	.count {
  		font-size: small;
  	}
  }
}


div.facet-list {
  
  padding-bottom: 1rem;

  form {
    padding: 10px;
  }

  .apply {
    float: right;
    font-size: small;
    padding: 0.5rem 0.5rem;
  }

  select {
    width: 100%;
  }

  .less, .more {
    padding-left: 6px;
    font-size: smaller;
  }


  .expand-btn {
    display: none;
  }

  > .card.collapsed {
    .collapse-btn {
      display: none;
    }
  
    .expand-btn {
      display: initial;
    }
  }


}

#papyri-facets .facet-list .active {
  font-weight: bold;
}

#papyri-facets .overflow .values {
  display: none;
}

#papyri-facets .overflow.visible .values {
  display: block;
}

#papyri-facets .overflow.visible {
  display: block;
}

#papyri-facets .overflow .less {
  display: none;
}

#papyri-facets .overflow.visible .more {
  display: none;
}

#papyri-facets .overflow.visible .less {
  display: block;
}