
/* --- JUMBOTRON --- */

#jumbotron {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;  
  }
}

#jumbotron-content {

  @extend .mx-auto;
  @include make-row();

  text-align: left;
  max-width: map-get($grid-breakpoints, xl) + 200;

  #left {

    @include make-col-ready();
    @include make-col(8);

    padding-top: 2rem;
    z-index: 20;

    #welcome, #lead {
      max-width: 40rem;
    }

    #welcome {
      margin-bottom: 1.5em;
      font-family: $papyri-font-light;

      @include media-breakpoint-down(md) {
        font-size: 28px;
      }

      @include media-breakpoint-down(xs) {
        font-size: 19px;
      }
    }

  }

  #right {

    z-index: 10;

    @include make-col-ready();
    @include make-col(4);

    padding-top: 15px;
    text-align: center;

    #pflanze {
       width: auto;
       height: 350px;
    }
  }


  .form-group {
    @include media-breakpoint-down(md) {
      flex-basis: 0;
    }

    margin-bottom: 0.5rem;

    > * {
      margin-bottom: 0.5rem;
    }
  }

  input, button {

    @include media-breakpoint-up(sm) {
        
      width: 20rem;
      height: 50px;


      font-size: 19px;
      }
  }

  input {
    margin-right: 1rem;
  }

  #lead {
    
    font-family: $papyri-font-light;
    font-size: 14px;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }
  }

}


/*  --- BODY --- */

#landing-body-content {
  @extend .mx-auto;
  max-width: map-get($grid-breakpoints, xl) + 200;

  padding-left: 1rem;
  padding-right: 1rem;
}



/* NUMBERS */

#numbers {

  @include make-row();

  line-height: 1.12;
  
  @include media-breakpoint-up(md) {
    padding-bottom: 4rem;
  }

  font-size: 28px;

  @include media-breakpoint-up(lg) {
    font-size: 36px;
  }

  .number-col {
    @include make-col-ready();

    @include media-breakpoint-up(md) {
      @include make-col(3);
    }

    @include media-breakpoint-down(sm) {
      @include make-col(6);
      margin-bottom: 1rem;
      font-size: 20px;
    }

  }

  .number-col-inner {

    border-top: solid $papyri-brand-secondary-bright 4px;
    padding-top: 8px;
  }

  .count, .what {
    display: block;
  }

  .count {
    font-weight: bold;
  }

  .what {
    font-family: $papyri-font-light;
  }
}


/* HIGHLIGHTS */

#highlights {
  @include make-row();

  div[class^="highlight"] {
    @include make-row();

    margin-bottom: 10rem;
  
  }

  .highlight-left p {
    margin-left: 30px;
  }

  .highlight-right p {
    margin-right: 30px;
  }

  .image, .text {
    @include make-col-ready();
  }

  .image {
    @include make-col(8);
  }
  
  .text {
    @include make-col(4);
  }

  .carousel-inner, .image > img {
    box-shadow: 2px 6px 9px -1px rgba(0, 0, 0, 0.2);
  }

  .text {
    font-family: $papyri-font-light;
    font-size: 36px;

  }

  /* ALL SLIDERS */

  .carousel-indicators {
    bottom: 0;
    margin-bottom: 15px;
  }


  /* TEXT SLIDER */

  #carousel-texts {

    background-color: $papyri-brand-secondary;

    .carousel-control {

      background-image: none;
      opacity: 0.7;

      &:hover {
        &.left {
          @include gradient-x($start-color: rgba(0,0,0,.5), $end-color: rgba(0,0,0,.0001));
        }
        &.right {
          right: 0;
          left: auto;
          @include gradient-x($start-color: rgba(0,0,0,.0001), $end-color: rgba(0,0,0,.5));
        }
      }
    }
  }

  .text-item {

    $gradient-width: 12%;

    position: relative;
    height: 364px;

    color: white;

    .quote-icon {
      position: absolute;

      top: 15px;
      left: 15px;
    }

    .bg-fade {
      position: absolute;
      right: 0;
    }

    .transcription {
      position: relative;
      padding: 35px 25px 25px 35px;
      font-size: 1.4rem;
    }

    .description {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 25px;
      background-color: rgba(0,0,0,0.65);
      height: 42%;
      z-index: 10;
    }

  }

}

#landing-features-row {
  @include make-row();

  margin-bottom: 3rem;

  .landing-features-inner {
    @include make-col-ready();
    @include make-col(12);

    margin-bottom: 2rem;
  }

  .card-deck {
    @include media-breakpoint-down(sm) {
     display: block;
     .card {
      margin-bottom: 1rem;
     }
    }
    padding-bottom: 1em;
  }

  .card-img-top {
    width: 100%;
  }

  .card-block {
    position: relative;
    margin: 15px;
  }

  .card-text {
    padding-bottom: 2rem;
  }

  .landing-feature .more {
    display: block;
    position: absolute;
    right: $card-spacer-x;
    bottom: $card-spacer-y;
  }

  .more {
    display: block;
    float: right;

    font-size: 1.2rem;
  }

}