
$papyri-navbar-height: 180px;


.navbar { 
  
  @extend .align-items-stretch;

  background-image: none;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 3px #999999, 0 -1px 0 #999999 inset;
  color: #bbbbbb;
  margin-bottom: 0;

  padding: 4px 8px;

  @include media-breakpoint-up(lg) {
    height: $papyri-navbar-height;
    padding: 30px 35px;
  }

  .nav-item > a, .navbar-title > a, a.login-btn {
    color: #FFFFFF;
  }

  .nav-menu ul a {
    a:link,
    a:visited {
      color: $body-color;
    }
  }



  .nav-title {
    display: inline-block;
    white-space: nowrap;
  }

  .nav-menu {
    font-family: "LatoLatinWebLight", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    padding-left: 15px;
  }

  .navbar-toggler {
    margin-left: auto;
    align-self: center;
  }

  #papyri-navbar-collapse {

    @extend .align-items-end;
    padding-bottom: 6px;

    @include media-breakpoint-only(lg) {
      .nav {
        flex-direction: column !important;
        line-height: 10px;
        margin-bottom: -8px;
      }
    }

    > ul > li {
      padding-right: 0.7rem;
      
    }

    .dropdown-toggle::after {
      margin-left: 0 !important;
    }
  
     @include media-breakpoint-only(lg) {
      .dropdown-item {
        padding: 8px 1.5rem !important;
      }
    }

  }


  .navbar-logo {
      width: auto;

      $navbar-logo-height: 90px;
      $navbar-logo-height-medium: 45px;
      $navbar-logo-height-small: 25px;
      height: $navbar-logo-height-small;

      margin-right: 5px;

      @include media-breakpoint-up(sm) {
        height: $navbar-logo-height-medium;
      }

      @include media-breakpoint-up(lg) {
        height: $navbar-logo-height;
        margin-right: 10px;
      }
      
    }

  .logos {
    @extend .align-items-center;
    white-space: nowrap;
    display: flex;
    margin-right: 5px;
  }

  

  .navbar-text {
    display: inline-block;
    font-weight: normal;
    line-height: 1em;
    padding: 0 2em;
  }

  .nav-center {

    text-align: center;

    @include media-breakpoint-down(xs) {
      text-align: left;
      padding-left: 0;
    }
  }

  .navbar-brand {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    padding: 0;
  }

  .title-wrapper {
    display: flex;
  }

  .navbar-title {
    display: flex;
    margin: 5px auto;

    .navbar-title-initial {
      margin-inline-end: -2px;
    }


    color: #FFFFFF;
    font-family: CinzelDecorative-Regular, sans-serif;
    white-space: normal;
    font-size: 20px;
    font-weight: 100;
    max-width: 300px;

    @include media-breakpoint-up(sm) {
      //padding-top: 6px;
      font-size: 35px;
    }

    @include media-breakpoint-up(lg) {
      //padding-top: 6px;
      font-size: 60px;
      line-height: 52px;
    }

    letter-spacing: 1px;
  }
  


  .login-btn{
    color: #DDDDDD;
    cursor: pointer;
    font-size: small;
    font-weight: bold;
    padding: 8px;
    // position: absolute;
    right: 7px;
    top: .3em;

    float: right;

    a:link,
    a:visited {
      color: #FFFFFF;
    }
  }

  form {
    float: right;
  }

  .form-control {
    border-color: #d8d5cf;
  }


}


.login-btn {
  font-size: smaller;
   

    padding-left: 0;

    @include media-breakpoint-up(md) {
      padding-left: 16px;
      text-align: center;
      padding-right: 0;
  }
}

.navbar-tools {

  padding: 0;
  > li {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }


}


.navbar-search {

  .btn {
    background-color: white;
  }

  .form-control, .btn {
    border-color: $papyri-primary-bright;
  }

  .btn-outline-secondary:hover {
    color: lighten($papyri-primary-bright, 20%) !important;
  }
}