/* Suchergebnisse */

#papyri-results {
  overflow: auto;
}

.papyri-search-results ul {
  padding-left: 0;
  list-style: none;
}


form.page-select {
  display: inline-block;
  padding-left: 1rem;

  .form-control {
    height: calc(2.5rem - 8px) !important;
    padding: 0rem 1rem;
    display: inline-block;
    font-size: $font-size-sm; 
  }
}

ul.results {
  @extend .list-unstyled;

  overflow: hidden;
}

.papyri-search-results {

  .constraints {
     
    margin-top: 30px;
    margin-bottom: 30px;

     th,
     td {
      padding-right: 15px;
    }

    // .constraint-field::after {
    //   content: ':';
    // } 
  }
  
  a {
    color: $body-color;
  }

  .one-result {

      @extend .media;

      .result-body {
        @extend .media-body;
      }


      &:hover {
        background-color: $gray-200;
      }

      .inv-no {
        color: $primary;
      }

      h4 {
        color: $papyri-brand-secondary;
      }

      -o-transition:.2s;
      -ms-transition:.2s;
      -moz-transition:.2s;
      -webkit-transition:.2s;
      transition:.2s;
  
      margin-bottom: 2rem;
  }


  .result-image {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @extend .align-self-center;

    width: 120px;
    height: 120px;
    margin-right: 15px;
    margin-left: 1rem;
  }
  
  // .result-image {


  //   .img-responsive {
  //      margin: 0 auto;
  //      max-width: 90%; 
  //   }
    
  //   padding: 10px;
  // }
}

.papyri-search-results {

  > h2 {
    margin-left: 6px;
  }

  .result-no {
    margin-top: 30px;
    font-size: 14px;
  }

  .hi {
    color: orange;
  }

  .summary p {
    display: inline;
  }

  .text-meta {
    padding-bottom: 1em;
  }

  .datierung, .herkunft {
    
    font-size: small;
    color: $gray-600;

    .glyphicon {

      padding-right: 4px;
    }
      
  }

  .msitemstructs .locus:not(:empty)+.title:not(:empty):before {
     content: ": ";
  }

}

/* Seitennavigation */

.page-nav {
  @extend .d-flex;
  margin-bottom: 1em;
}

/* Allgemein */

.spinner {
  display: none;
}

.spinner.busy {
  display: block;
}
