.papyri-publications {
  padding-bottom: 1rem;

  ul {
    list-style: none;
    padding-left: 0;

    li {
      padding-bottom: 0.3rem;
    }
  }

  .pub-no:not(:empty):after {
    content: ": ";
  }
}

