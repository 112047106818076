@use "sass:math";

$section-title-padding-top: 10px;
$section-title-border-margin-top: 5px;
$section-title-margin-bottom: 10px;
$section-title-font-size: 20px;
$section-title-line-height: 1.5;

$section-title-height: $section-title-padding-top
                        + $section-title-border-margin-top
                        + $section-title-margin-bottom
                        + $section-title-font-size * $section-title-line-height;

@mixin section-title {
  box-sizing: border-box;
  font-size: $section-title-font-size;
  font-weight: $headings-font-weight;
  font-family: $headings-font-family;
  padding-top: $section-title-padding-top;
  margin-top: -10px;

  &:after {
    box-sizing: border-box;
    content: "";
    display: block;
    margin-top: $section-title-border-margin-top;
    padding-bottom: $section-title-margin-bottom;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.sticky {
    position: sticky;
    top: 0;
    background-color: $papyri-background;
  }

  &.noborder {
    border-bottom: none;
  }
}